<template>
    <div class="addRounds-container">
        <div class="addRounds-title">
            <div class="back-router" @click="goPage">
                <i class="el-icon-back"></i>
                <div>Back</div>
            </div>
            <div class="addRounds-title-name">Upload NFTs</div>
        </div>
        <div class="uploadedMint">
            <div class="uploadedMint-title">Uploaded / Minted</div>
            <div class="uploadedMint-count">{{roundsList.length}}/1000</div>
        </div>
        <div class="upload record">
            <div class="uploadedMint-title">Upload History</div>
            <div class="uploadedMint-title-info">Uploaded NFTs can be deleted, added, or replaced by a number</div>
        </div>
        <div class="add-new-round-btn" @click="addRound">
            <div class="add-from-list">Single Upload</div>
        </div>
        <div id="form-list">
            <el-table :data="roundsList" style="width: 100%" :border="true">
                <el-table-column label="Number" width="100" align="center">
                    <template slot-scope="scope">{{scope.$index+1}}</template>
                </el-table-column>
                <el-table-column prop="pic" label="Pic Preview" width="200" align="center">
                    <template slot-scope="scope">
                        <img :src="`https://cloudflare-ipfs.com/ipfs/${scope.row.image}`" class="nftImg">
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="Name" width="130" align="center">
                    <template slot-scope="scope">
                        <div class="row-name">{{scope.row.name}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="Desc" label="Desc" width="500" align="center">
                    <template slot-scope="scope">
                        <div class="row-info">{{scope.row.description}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="Manage" label="Manage" align="center">
                    <template slot-scope="scope">
                        <!-- <i class="el-icon-delete" @click="removeItem(scope.row)"></i> -->
                        <el-popover placement="top" width="190" trigger="click" :ref="scope.row.id">
                            <p>Are you sure to delete？</p>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="cancel(scope.row.id)">cancel</el-button>
                                <el-button type="primary" size="mini" @click="removeItem(scope.row)">ok</el-button>
                            </div>
                            <i slot="reference" class="el-icon-delete"></i>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
const wallet = require('../../web3/common/wallet.js');
export default {
    data() {
        return {
            baseUrlData: '',
            seriesid: '',
            roundsList: [],
            querytokenaddress: '',
        };
    },
    computed: {
        mapState() {
            return this.$store.getters.walletInfo
        },

    },
    methods: {
        goPage() {
            this.$router.go(-1)
        },
        addRound() {
            this.$router.push({ path: '/uploadNFTs', query: { id: this.seriesid, tokenAddress: this.querytokenaddress } })
        },
        removeItem(row) {
            console.log(row.id)
            let params = {
                id: row.id
            }
            this.$axios.get(this.baseUrlData + this.$api.post_deletemetainfos, params).then(res => {
                this.$refs[row.id].doClose()
                this.queryListData()
            })
        },
        cancel(index) {
            this.$refs[index].doClose()
        },
        queryListData() {
            if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    let admin = info.address.toLowerCase();
                    let network = info.network;
                    let params = {
                        admin: admin,
                        seriesid: this.seriesid,
                        network: network,
                        tokenaddress: this.querytokenaddress
                    }
                    this.$axios.get(this.baseUrlData + this.$api.post_getmetainfos, params).then(res => {
                        this.roundsList = res.result
                    })
                })
            }
        },
    },
    created() {
        this.baseUrlData = localStorage.getItem('baseUrl')
        this.seriesid = JSON.parse(this.$route.query.id)
        this.querytokenaddress = this.$route.query.tokenAddress
        this.queryListData()
    }
};
</script>
<style lang="scss" src='../../assets/css/addRounds.scss' scoped></style>